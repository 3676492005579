<template>
  <div>
    <v-layout wrap justify-center pt-16>
      <v-flex xs12>
        <v-layout wrap justify-center pb-5>
          <v-flex xs12 sm11>
            <v-layout wrap>
              <v-flex xs3 sm4 md5 pt-5> <v-divider></v-divider></v-flex>
              <v-flex xs6 sm4 md2>
                <span
                  style="
                    font-family: poppinsmedium;
                    color: #30b868;
                    font-size: 22px;
                  "
                  >Eco Shops</span
                >
              </v-flex>
              <v-flex xs3 sm4 md5 pt-5> <v-divider></v-divider></v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center pt-5 pb-10 class="gradientTop">
          <v-flex xs12>
            <v-layout wrap justify-center px-5>
              <v-flex
                xs12
                sm6
                md4
                data-aos="flip-down"
                data-aos-ease="ease"
                data-aos-duration="1500"
                data-aos-delay="500"
                v-for="(item, i) in shop"
                :key="i"
                pa-5
              >
              <router-link :to="'/ecoShop?sellerName=' + item.shopName">
                <v-img :src="mediaUURL + item.profilePicture">
                  <template v-slot:placeholder>
                    <ImageLoader />
                  </template>
                  <v-layout wrap fill-height class="gradientItem">
                    <v-flex xs12 md12>
                      <v-layout wrap>
                        <v-flex
                          xs12
                          sm6
                          px-6
                          text-left
                          style="
                            position: absolute;
                            bottom: 80px;
                            color: #fff;
                            text-align: left;
                          "
                        >
                          <!-- <v-icon color="white">mdi-map-marker-outline</v-icon> -->
                          <v-img
                            height="30px"
                            contain
                            src="./../../assets/icons/map.png"
                          ></v-img>
                        </v-flex>
                        <v-flex
                          px-6
                          pt-6
                          xs12
                          sm6
                          md5
                          lg4
                          style="
                            position: absolute;
                            bottom: 20px;
                            color: #fff;
                            text-align: left;
                          "
                          text-left
                        >
                          <span
                            class="proCardtitle text-capitalize"
                            style="font-family: poppinsmedium"
                          >
                            {{ item.shopName }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-img>
                </router-link>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout
          wrap
          justify-center
          v-if="home"
          pt-16
          pb-10
          data-aos="zoom-in-up"
          data-aos-ease="ease"
          data-aos-duration="1500"
          data-aos-delay="500"
        >
          <v-flex xs12 sm10>
            <v-img
              :src="mediaUURL + home.middleBanner"
              style="border-radius: 10px"
            >
              <v-layout
                wrap
                justify-start
                pa-5
                px-xs-0
                px-sm-0
                px-md-10
                px-lg-10
                px-xl-10
                pt-16
                fill-height
              >
                <v-flex
                  xs12
                  sm10
                  md6
                  align-self-center
                  text-left
                  style="letter-spacing: 0.5px"
                >
                  <span
                    :style="{
                      'line-height':
                        $vuetify.breakpoint.name == 'xs' ? '25px' : '30px',
                    }"
                    style="
                      font-family: poppinsbold;
                      color: #363636;
                      font-size: 25px;
                      text-transform: uppercase;
                    "
                    >Conscious Living, Conscious Giving Shop with Purpose</span
                  >
                  <br />
                  <br />
                  <span style="color: #ffffff; font-family: poppinsmedium"
                    >Every purchase you make contributes to a greater cause.
                    With a focus on ethical sourcing, sustainable practices, and
                    empowering communities, our store helps you shop with a
                    purpose. Be a conscious consumer and make a difference with
                    every choice you make</span
                  >
                </v-flex>
              </v-layout>
              <template v-slot:placeholder>
                <ImageLoader />
              </template>
            </v-img>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["home"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      shop:[],
      items: [
        {
          image: require("./../../assets/images/eco1.png"),
          name: "Mudumalai Eco Shop",
        },
        {
          image: require("./../../assets/images/eco2.png"),
          name: "Cuddalore Eco shop",
        },
        {
          image: require("./../../assets/images/eco3.png"),
          name: "Tiruvarur Eco shop",
        },
        {
          image: require("./../../assets/images/eco4.png"),
          name: "Salem Eco Shop",
        },
        {
          image: require("./../../assets/images/eco5.png"),
          name: "Villupuram Eco Shop",
        },
        {
          image: require("./../../assets/images/eco6.png"),
          name: "Erode Eco shop",
        },
        {
          image: require("./../../assets/images/eco7.png"),
          name: "Dindigul Eco shop",
        },
        {
          image: require("./../../assets/images/eco8.png"),
          name: "Sivagangai Eco shop",
        },
        {
          image: require("./../../assets/images/eco9.png"),
          name: "Perambalur Eco shop",
        },
      ],
    };
  },
  beforeMount() {
    this.getData()
  },
  methods: {
     getData() {
      this.appLoading = true;
      axios({
        url: "/sellerList",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.shop = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.gradient {
  width: 0;
  height: 0;
  border-left: 50px solid transparent; /* Adjust the size as needed */
  border-right: 50px solid transparent; /* Adjust the size as needed */
  border-bottom: 100px solid rgba(0, 0, 0, 0.49);
}
.gradientItem {
  background: linear-gradient(
    190.17deg,
    rgba(0, 0, 0, 0) 10.54%,
    rgba(0, 0, 0, 0) 64.97%,
    rgba(0, 0, 0, 0.51) 65.83%
  );
}
.gradientTop {
  background: linear-gradient(
    180deg,
    rgba(62, 62, 62, 0) 0%,
    rgba(45, 45, 45, 0.07) 100%
  );
}
</style>