<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-center pb-5 pt-10>
          <v-flex xs12 sm11>
            <v-layout wrap>
              <v-flex xs3 sm5 pt-5> <v-divider></v-divider></v-flex>
              <v-flex xs6 sm2>
                <span
                  style="
                    font-family: poppinsmedium;
                    color: #30b868;
                    font-size: 22px;
                  "
                  >About Us</span
                >
              </v-flex>
              <v-flex xs3 sm5 pt-5> <v-divider></v-divider></v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center style="background-color: #f8f8f8">
          <v-flex xs12>
            <v-layout wrap justify-center>
              <v-flex xs11 sm11 px-1>
                <v-layout wrap justify-space-around py-15>
                  <v-flex xs12 sm8 md8>
                    <v-layout wrap justify-center pr-xs-0 pr-sm-0 pr-md-10 pr-lg-10 pr-xl-10>
                      <v-flex xs12 sm11 v-if="home.aboutUs" text-left align-self-center>
                        <span
                          style="
                            color: #000000;
                            font-family: RobotoRegular;
                            font-size: 15px;
                            line-height: 25px;
                            letter-spacing: 1px;
                            text-align: justify;
                          "
                          v-html="home.aboutUs.description"
                          ></span
                        >
                      </v-flex>
                    </v-layout>
                    <v-layout wrap justify-start pt-5 pr-xs-0 pr-sm-0 pr-md-10 pr-lg-10 pr-xl-10>
                      <v-flex xs12 sm6 md3 lg3 text-center v-for="(item, i) in officials" :key="i">
                        <v-layout wrap justify-start >
                          <v-flex
                            xs12
                            sm11
                            data-aos="zoom-in-up"
                            data-aos-ease="ease"
                            data-aos-duration="1500"
                            data-aos-delay="500"
                          >
                            <v-img
                              contain
                              height="50px"
                              :src="mediaUURL + item.image"
                            ></v-img>
                            <br />
                            <span
                              style="
                                color: #000000;
                                font-family: RobotoMedium;
                                font-size: 15px;
                              "
                              >{{ item.name }}</span
                            >
                            <br />
                            <span
                              style="
                                color: #000000;
                                font-family: RobotoLight;
                                font-size: 12px;
                              "
                              >{{ item.designation }}</span
                            >
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex
                    xs12
                    sm4
                    md4
                    text-right
                    data-aos="zoom-in-up"
                    data-aos-ease="ease"
                    data-aos-duration="1500"
                    data-aos-delay="500"
                  >
                    <v-img src="./../../assets/images/logo.png">
                      <template v-slot:placeholder>
                        <ImageLoader />
                      </template>
                    </v-img>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout> </v-flex
        ></v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props:["home","officials"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,

      items: [
        {
          image: require("./../../assets/images/1pic.png"),
          name: "Dr. M. Mathiventhan",
          position: "Honourable Forest Minister",
        },
        {
          image: require("./../../assets/images/2pic.png"),
          name: "Tmt. Supriya Sahu, IAS",
          position: "Additional Chief Secretary to Government",
        },
        {
          image: require("./../../assets/images/3pic.png"),
          name: "Thiru.Subrat Mohapatra I.F.S ",
          position: "Principal Chief Conservator of Forests (HoFF)",
        },
        {
          image: require("./../../assets/images/4pic.png"),
          name: "Thiru.Srinivas R. Reddy, I.F.S.,",
          position:
            "Principal Chief ConservatorofForests & Chief WildlifeWarden",
        },
      ],
    };
  },
  beforeMount() {},
  methods: {},
};
</script>