var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","align-self-center":""}},[_c('div',{staticClass:"Headd"},[_c('v-img',{attrs:{"src":_vm.mediaUURL + _vm.caraouselitems.image,"height":"750px"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}])},[_c('v-layout',{attrs:{"wrap":"","justify-start":"","pa-5":"","px-xs-0":"","px-sm-0":"","px-md-10":"","px-lg-10":"","px-xl-10":"","pt-16":"","fill-height":"","data-aos":"zoom-in-up","data-aos-ease":"ease","data-aos-duration":"1500","data-aos-delay":"500"}},[_c('v-flex',{style:({
                'line-height':
                  _vm.$vuetify.breakpoint.name == 'xs' ? '35px' : '60px',
              }),attrs:{"xs10":"","sm6":"","align-self-center":"","text-left":""}},[_c('span',{staticStyle:{"font-family":"poppinsregular","color":"#fcff70","text-shadow":"2px 2px 5px #000000","font-size":"18px"}},[_vm._v("Nature's Symphony")]),_c('br'),_c('span',{staticStyle:{"font-family":"poppinssemibold","text-transform":"uppercase","text-shadow":"2px 2px 3px #000000","color":"#ffffff"},style:({
                  'font-size':
                    _vm.$vuetify.breakpoint.name == 'xs' ? '30px' : '50px',
                }),domProps:{"innerHTML":_vm._s(_vm.caroselText.content)}}),_c('span',{staticStyle:{"font-family":"RobotoMedium","color":"#ffffff","text-shadow":"2px 2px 5px #000000","font-size":"18px"}},[_vm._v("THE ECO STORE")]),_c('v-flex',{attrs:{"xs12":""}},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"#30B868","dark":"","large":"","to":'/ecoShopList'}},[_c('span',{staticStyle:{"font-family":"RobotoRegular"}},[_vm._v("Shop Now")])])],1)],1)],1)],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }