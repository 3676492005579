<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-center pb-5 pt-10>
          <v-flex xs12 sm11>
            <v-layout wrap>
              <v-flex xs3 sm4 md5 pt-5> <v-divider></v-divider></v-flex>
              <v-flex xs6 sm4 md2>
                <span
                  style="
                    font-family: poppinsmedium;
                    color: #30b868;
                    font-size: 22px;
                  "
                  >Our Story</span
                >
              </v-flex>
              <v-flex xs3 sm4 md5 pt-5> <v-divider></v-divider></v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center v-if="ourStory">
          <v-flex xs12>
            <!-- <div class="Headd"> -->
            <!-- <v-img src="./../../assets/images/story.png"  :height="$vuetify.breakpoint.name == 'xs'
            ? '1000px'
            : $vuetify.breakpoint.name == 'sm'
            ? '710px'
            : $vuetify.breakpoint.name == 'md'
            ? '710px'
            : $vuetify.breakpoint.name == 'lg'
            ? '710px'
            : '790px'"> -->
            <div :style="{'height':$vuetify.breakpoint.name == 'xs'
            ? '1120px'
            : $vuetify.breakpoint.name == 'sm'
            ? '900px'
            : $vuetify.breakpoint.name == 'md'
            ? '600px'
            : $vuetify.breakpoint.name == 'lg'
            ? '540px'
            : '860px'}" 
            style="background: var(--green, linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #CEFFE2 100%));;">
              <v-layout wrap justify-center>
                <v-flex xs11 sm11>
                  <v-layout wrap justify-center pt-5>
                    <v-flex
                      xs12
                      sm12
                      md4
                      data-aos="zoom-in-down"
                      data-aos-ease="ease"
                      data-aos-duration="1500"
                      data-aos-delay="500"
                    >
                      <v-img :src="mediaUURL + ourStory.image" style=" border-radius: 2%;">
                        <template v-slot:placeholder>
                          <ImageLoader /> </template
                      ></v-img>
                    </v-flex>
                    <v-flex xs12 sm12 md8>
                      <v-layout wrap justify-center>
                        <v-flex xs12 sm12 md11 text-left>
                          <span
                            style="
                              color: #000000;
                              font-family: RobotoRegular;
                              font-size: 15px;
                              text-align: justify;
                              line-height: 25px;
                              letter-spacing: 1px;
                            "
                            v-html="ourStory.description"></span
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex xs12 text-left pt-15>
                      <span style="font-family: poppinssemibold"
                        >The Perks of Shopping at Eco Store</span
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <!-- <template v-slot:placeholder>
                <ImageLoader />
              </template> -->
            <!-- </v-img> -->
            <!-- </div>
            <div class="Taill"> -->
          </div>
            <v-layout wrap justify-center>
              <v-flex
                xs11
                sm11
                :style="
                  $vuetify.breakpoint.name == 'lg'
                    ? 'position: relative !important; margin-top: -6%;'
                    : $vuetify.breakpoint.name == 'md'
                    ? 'position: relative !important; margin-top: -6%;'
                    : $vuetify.breakpoint.name == 'xl'
                    ? 'position: relative !important; margin-top: -4%;'
                    : $vuetify.breakpoint.name == 'sm'
                    ? 'position: absolute !important; margin-top: 0%;'
                    : $vuetify.breakpoint.name == 'xs'
                    ? 'position: absolute !important; margin-top: 0%;'
                    : 'position: absolute !important; margin-top: 0%;'
                "
              >
                <v-layout wrap justify-center v-if="goals.points" data-aos="zoom-in-up"
                    data-aos-ease="ease"
                    data-aos-duration="1500"
                    data-aos-delay="500">
                  <!-- v-for="(item,i) in goals.points" :key="i" -->
                  <v-flex
                    xs12
                    sm6
                    md6
                    pt-10
                    pr-xs-0
                    pr-sm-5
                    pr-md-5
                    pr-lg-5
                    pr-xl-5
                  >
                    <v-card rounded="xl" color="#F8FFF3">
                      <v-layout wrap pa-5>
                        <v-flex xs2 sm1>
                          <v-img src="./../../assets/images/icon1.png"></v-img>
                        </v-flex>
                        <v-flex xs10 sm10 pl-5 text-left>
                          <span
                            style="
                              color: #000000;
                              font-family: RobotoMedium;
                              padding-bottom: 80px;
                            "
                            >{{ goals.points[0].heading }}</span
                          >
                          <br />
                          <span
                            style="
                              color: #000000;
                              font-family: RobotoRegular;
                              font-size: 14px;
                            "
                            >{{ goals.points[0].description }}</span
                          >
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                  <v-flex
                    xs12
                    sm6
                    pt-10
                    pl-xs-0
                    pl-sm-2
                    pl-md-5
                    pl-lg-5
                    pl-xl-5
                  >
                    <v-card rounded="xl" color="#FFFAF3">
                      <v-layout wrap pa-5>
                        <v-flex xs2 sm1>
                          <v-img src="./../../assets/images/icon2.png"></v-img>
                        </v-flex>
                        <v-flex xs10 sm10 pl-5 text-left>
                          <span
                            style="color: #000000; font-family: RobotoMedium"
                            >{{ goals.points[1].heading }}</span
                          >
                          <br />
                          <span
                            style="
                              color: #000000;
                              font-family: RobotoRegular;
                              font-size: 14px;
                            "
                            >{{ goals.points[1].description }}</span
                          >
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                  <v-flex
                    xs12
                    sm6
                    pt-10
                    pr-xs-0
                    pr-sm-5
                    pr-md-5
                    pr-lg-5
                    pr-xl-5
                  >
                    <v-card rounded="xl" color="#FFF7F6">
                      <v-layout wrap pa-5>
                        <v-flex xs2 sm1>
                          <v-img src="./../../assets/images/icon3.png"></v-img>
                        </v-flex>
                        <v-flex xs10 sm10 pl-5 text-left>
                          <span
                            style="color: #000000; font-family: RobotoMedium"
                            >{{ goals.points[2].heading }}</span
                          >
                          <br />
                          <span
                            style="
                              color: #000000;
                              font-family: RobotoRegular;
                              font-size: 14px;
                            "
                            >{{ goals.points[2].description }}</span
                          >
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                  <v-flex
                    xs12
                    sm6
                    pt-10
                    pl-xs-0
                    pl-sm-2
                    pl-md-5
                    pl-lg-5
                    pl-xl-5
                  >
                    <v-card rounded="xl" color="#F3FCFF">
                      <v-layout wrap pa-5>
                        <v-flex xs2 sm1>
                          <v-img src="./../../assets/images/icon4.png"></v-img>
                        </v-flex>
                        <v-flex xs10 sm10 pl-5 text-left>
                          <span
                            style="color: #000000; font-family: RobotoMedium"
                            >{{ goals.points[3].heading }}</span
                          >
                          <br />
                          <span
                            style="
                              color: #000000;
                              font-family: RobotoRegular;
                              font-size: 14px;
                            "
                            >{{ goals.points[3].description }}</span
                          >
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <!-- </div> -->
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props:["ourStory","goals"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
    };
  },
};
</script>
<style>
.theme--light.v-tabs-items {
  background-color: transparent !important;
  background: transparent linear-gradient(180deg, #000000 0%, #54545450 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}

.btn-border {
  border: 2px solid #30d5c8;
}

.boom {
  background-color: #fff;
  z-index: 2px;
}

.clash {
  z-index: 1px;
  /* padding-bottom: 250px; */
  position: relative;
}

.Headd {
  position: relative;
}

.Taill {
  position: absolute;
  top: 650px;
  z-index: 3;
  right: 0;
  left: 0;
  display: grid;
}
</style>
