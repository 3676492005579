var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('PageLoader',{attrs:{"storage":_vm.appLoading}}),(_vm.ServerError)?_c('ServerError'):_vm._e(),_c('v-snackbar',{attrs:{"color":"#F86031","right":"","timeout":_vm.timeout},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"text-left":"","align-self-center":""}},[_vm._v(_vm._s(_vm.msg))]),_c('v-flex',{attrs:{"text-right":""}},[_c('v-btn',{attrs:{"small":"","ripple":false,"text":""},on:{"click":function($event){_vm.showSnackBar = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1),_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('CarouselItem')],1)],1),_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('About',{attrs:{"home":_vm.home,"officials":_vm.officials}})],1)],1),_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('OurStory',{attrs:{"ourStory":_vm.ourStory,"goals":_vm.goals}})],1)],1),_c('v-layout',{style:({
      'padding-top':
        _vm.$vuetify.breakpoint.name == 'xs'
          ? '800px'
          : _vm.$vuetify.breakpoint.name == 'sm'
          ? '400px'
          : _vm.$vuetify.breakpoint.name == 'md'
          ? '0px'
          : _vm.$vuetify.breakpoint.name == 'lg'
          ? '0px'
          : '0px',
    }),attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('EcoShop',{attrs:{"home":_vm.home}})],1)],1),_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('Blog')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }