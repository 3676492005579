<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#F86031" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <CarouselItem />
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <About :home="home" :officials="officials" />
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <OurStory :ourStory="ourStory" :goals="goals" />
      </v-flex>
    </v-layout>
    <v-layout
      wrap
      justify-center
      :style="{
        'padding-top':
          $vuetify.breakpoint.name == 'xs'
            ? '800px'
            : $vuetify.breakpoint.name == 'sm'
            ? '400px'
            : $vuetify.breakpoint.name == 'md'
            ? '0px'
            : $vuetify.breakpoint.name == 'lg'
            ? '0px'
            : '0px',
      }"
    >
      <v-flex xs12>
        <EcoShop :home="home" />
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <Blog />
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
import CarouselItem from "./carouselItem";
import About from "./about";
import OurStory from "./ourStory";
import EcoShop from "./ecoShop";
import Blog from "./blog";
//   import VideoPage from "@/components/Home/videoPage";
export default {
  components: {
    CarouselItem,
    About,
    OurStory,
    EcoShop,
    Blog,
    //   VideoPage
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      category: [],
      programs: [],
      home: {},
      ourStory: {},
      officials: [],
      goals: {},
      programlen: null,
      halfsize: null,
      model: 0,
      caraouselitems: [
        {
          image: "Home.jpg",
          quote:
            "Where humans and <br/> the wild things, <br />peacefully co-exist",
        },
        {
          image: "Home2.jpg",
          quote:
            "Among the top ten <br/> best managed <br/> Tiger Reserves <br/> in the country ",
        },
      ],
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/website/content",
      })
        .then((response) => {
          this.appLoading = false;
          this.home = response.data.websiteDetails;
          this.officials = response.data.officials;
          this.ourStory = response.data.websiteDetails.ourStory;
          this.goals = response.data.websiteDetails.goals;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>