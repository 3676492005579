<template>
  <div>
    <v-layout wrap>
      <v-flex xs12 align-self-center>
        <div class="Headd">
          <!-- :src="mediaURL + storage.image" -->
          <v-img :src="mediaUURL + caraouselitems.image" height="750px">
            <v-layout
              wrap
              justify-start
              pa-5
              px-xs-0
              px-sm-0
              px-md-10
              px-lg-10
              px-xl-10
              pt-16
              fill-height
              data-aos="zoom-in-up"
              data-aos-ease="ease"
              data-aos-duration="1500"
              data-aos-delay="500"
            >
              <v-flex
                xs10
                sm6
                align-self-center
                text-left
                :style="{
                  'line-height':
                    $vuetify.breakpoint.name == 'xs' ? '35px' : '60px',
                }"
              >
                <span
                  style="
                    font-family: poppinsregular;
                    color: #fcff70;
                    text-shadow: 2px 2px 5px #000000;
                    font-size: 18px;
                  "
                  >Nature's Symphony</span
                >
                <br />
                <span
                  style="
                    font-family: poppinssemibold;
                    text-transform: uppercase;
                    text-shadow: 2px 2px 3px #000000;
                    color: #ffffff;
                  "
                  :style="{
                    'font-size':
                      $vuetify.breakpoint.name == 'xs' ? '30px' : '50px',
                  }"
                  v-html="caroselText.content"
                >
                  <!-- Embrace the Harmony of Wildlife Handcrafted Treasures -->
                </span>
                <span
                  style="
                    font-family: RobotoMedium;
                    color: #ffffff;
                    text-shadow: 2px 2px 5px #000000;
                    font-size: 18px;
                  "
                  >THE ECO STORE</span
                >
                <v-flex xs12>
                  <v-btn
                    color="#30B868"
                    dark
                    large
                    class="text-capitalize"
                    :to="'/ecoShopList'"
                  >
                    <span style="font-family: RobotoRegular">Shop Now</span>
                  </v-btn>
                </v-flex>
              </v-flex>
            </v-layout>
            <template v-slot:placeholder>
              <ImageLoader />
            </template>
          </v-img>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  data: () => ({
    model: 0,
    tab: 0,
    date: new Date().toISOString().substr(0, 10),
    date1: new Date().toISOString().substr(0, 10),
    menu: false,
    prog: "",
    newProg: "",
    caraouselitems: [],
    caroselText: [],
  }),
  beforeMount() {
    this.getCarousel();
  },
  methods: {
    getCarousel() {
      axios({
        method: "GET",
        url: "/carosel",
      })
        .then((response) => {
          this.caraouselitems = response.data.caroselImage;
          this.caroselText = response.data.caroselText;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
  <style>
.theme--light.v-tabs-items {
  background-color: transparent !important;
  background: transparent linear-gradient(180deg, #000000 0%, #54545450 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}

.btn-border {
  border: 2px solid #30d5c8;
}

.boom {
  background-color: #fff;
  z-index: 2px;
}

.clash {
  z-index: 1px;
  /* padding-bottom: 250px; */
  position: relative;
}

.Headd {
  position: relative;
}

.Taill {
  position: absolute;
  top: 650px;
  z-index: 3;
  right: 0;
  left: 0;
  display: grid;
}

.under {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: -1;
}

.over {
  position: absolute;
  left: 40px;
  top: 10px;
  z-index: -1;
}

@media only screen and (min-device-width: 360px) and (max-device-width: 900px) {
  .hig {
    top: 640px !important;
  }
}

@media only screen and (min-device-width: 900px) and (max-device-width: 960px) {
  .hig {
    top: 640px !important;
  }
}

@media only screen and (min-device-width: 960px) and (max-device-width: 1200px) {
  .hig {
    top: 600px !important;
  }
}

@media only screen and (min-device-width: 1200px) and (max-device-width: 1300px) {
  .hig {
    top: 600px !important;
  }
}

@media only screen and (min-device-width: 1300px) and (max-device-width: 1400px) {
  .hig {
    top: 1300px !important;
  }
}

@media only screen and (min-device-width: 1400px) and (max-device-width: 1500px) {
  .hig {
    top: 1280px !important;
  }
}

@media only screen and (min-device-width: 1500px) and (max-device-width: 1800px) {
  .hig {
    top: 1280px !important;
  }
}

@media only screen and (min-device-width: 1800px) and (max-device-width: 2000px) {
  .hig {
    top: 1280px !important;
  }
}

@media only screen and (min-device-width: 2000px) and (max-device-width: 3000px) {
  .hig {
    top: 1280px !important;
  }
}

@media only screen and (min-device-width: 3000px) and (max-device-width: 6000px) {
  .hig {
    top: 1280px !important;
  }
}
</style>
  