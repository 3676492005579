<template>
  <div>
    <v-layout wrap justify-center v-if="blog.length>0">
      <v-flex xs11 sm11>
        <v-layout wrap>
          <v-flex xs3 sm4 md5 pt-5> <v-divider></v-divider></v-flex>
          <v-flex xs6 sm4 md2>
            <span
              style="
                font-family: poppinsmedium;
                color: #30b868;
                font-size: 22px;
              "
              >Our Blogs</span
            >
          </v-flex>
          <v-flex xs3 sm4 md5 pt-5> <v-divider></v-divider></v-flex>
        </v-layout>
        <v-layout wrap justify-center pt-10>
          <v-flex xs12 sm11>
            <v-layout wrap justify-center>
              <v-flex
                xs12
                sm12
                md6
                pr-xs-0
                pr-sm-0
                pr-md-5
                pr-lg-5
                pr-xl-5
                data-aos="zoom-in-up"
                data-aos-ease="ease"
                data-aos-duration="1500"
                data-aos-delay="500"
              >
                <v-layout wrap>
                  <v-flex xs12 v-if="blog[0]">
                    <v-img
                      :src="mediaUURL + blog[0].image"
                      style="border-radius: 2%"
                    >
                      <template v-slot:placeholder>
                        <ImageLoader />
                      </template>
                    </v-img>
                  </v-flex>
                  <v-flex xs12 text-left py-5 v-if="blog[0]">
                    <span style="font-family: poppinsmedium; color: #000000">{{
                      blog[0].tittle
                    }}</span>
                  </v-flex>
                  <v-flex xs12 text-left v-if="blog[0]">
                    <span
                      style="
                        font-family: RobotoRegular;
                        color: #2c2c2c;
                        font-size: 14px;
                        line-height: 25px;
                        letter-spacing: 1px;
                      "
                       v-html="blog[0].content.slice(0,190)"
                      >
                    </span>
                    <span v-if="blog[0].content.length > 190"> ... </span>
                  </v-flex>
                  <v-flex xs12 sm6 pt-3 text-left v-if="blog[0]">
                    <span
                      style="
                        color: #30b868;
                        font-family: poppinsmedium;
                        font-size: 12px;
                      "
                      >{{ formatDate(blog[0].create_date) }}</span
                    >
                  </v-flex>
                  <v-flex xs11 sm5 pt-3 text-right v-if="blog[0]">
                    <router-link :to="'/blogView?id=' + blog[0]._id">
                      <span
                        style="
                          color: #30b868;
                          font-family: poppinsmedium;
                          font-size: 12px;
                        "
                        >READ MORE</span
                      >
                    </router-link>
                  </v-flex>
                  <v-flex xs1 sm1 pt-4 pa-2>
                    <v-img
                      contain
                      height="20px"
                      width="30px"
                      src="./../../assets/icons/arrow.png"
                    ></v-img>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex>
                <v-divider vertical></v-divider>
              </v-flex>
              <v-flex
                xs12
                sm12
                md6
                data-aos="zoom-in-up"
                data-aos-ease="ease"
                data-aos-duration="1500"
                data-aos-delay="500"
                pl-xs-0
                pl-sm-0
                pl-md-5
                pl-lg-5
                pl-xl-5
              >
                <v-layout
                  wrap
                  v-for="(item, i) in blog.slice(1, 10)"
                  :key="i"
                  pb-5
                >
                  <v-flex xs12 sm4>
                    <router-link :to="'/blogView?id=' + item._id">
                      <v-img
                        :src="mediaUURL + item.image"
                        style="border-radius: 5%"
                      >
                        <template v-slot:placeholder> <ImageLoader /> </template
                      ></v-img>
                    </router-link>
                  </v-flex>
                  <v-flex
                    xs12
                    sm8
                    text-left
                    pl-xs-0
                    pl-sm-5
                    pl-md-5
                    pl-lg-5
                    pl-xl-5
                  >
                    <v-layout wrap>
                      <v-flex xs12>
                        <span
                          style="color: #000000; font-family: poppinsmedium"
                          >{{ item.tittle.slice(0, 40) }}</span
                        >
                        <span v-if="item.tittle.length > 40"> ... </span>
                      </v-flex>
                      <v-flex xs12 pt-3>
                        <span
                          style="
                            font-family: RobotoRegular;
                            color: #000000;
                            font-size: 14px;
                            line-height: 25px;
                            letter-spacing: 1px;
                          "
                          v-html="item.content.slice(0, 140)"
                          >
                        </span>
                        <span v-if="item.content.length > 140"> ... </span>
                      </v-flex>
                      <v-flex xs12 sm6 pt-3>
                        <span
                          style="
                            color: #30b868;
                            font-family: poppinsmedium;
                            font-size: 12px;
                          "
                          >{{ formatDate(item.create_date) }}</span
                        >
                      </v-flex>
                      <v-flex xs11 sm5 pt-3 text-right>
                        <router-link :to="'/blogView?id=' + item._id">
                          <span
                            style="
                              color: #30b868;
                              font-family: poppinsmedium;
                              font-size: 12px;
                            "
                            >READ MORE</span
                          >
                        </router-link>
                      </v-flex>
                      <v-flex xs1 sm1 pt-4 pa-2>
                        <v-img
                          contain
                          height="20px"
                          width="30px"
                          src="./../../assets/icons/arrow.png"
                        ></v-img>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12>
        <v-img src="./../../assets/images/blog.png">
          <template v-slot:placeholder>
            <ImageLoader />
          </template>
        </v-img>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      blog: [],
      items: [
        {
          image: require("./../../assets/images/blog2.png"),
          title: "Tamil Nadu adds 24 reserve forests in last two years",
          text: "Tamil Nadu has added 24 reserve forests covering an area of 3585.3 hectares in the last two years, as per the Forest Department.Under the Green Tamil Nadu Mission, which aims to increase ",
        },
        {
          image: require("./../../assets/images/blog3.png"),
          title: "TN forest guard injured in gaur attack at STR dies",
          text: "A 38-year-old forest guard, who was being treated after being attacked by an Indian gaur at the Sathyamangalam Tiger Reserve (STR), died on Sunday. Officials from the forest department said",
        },
        {
          image: require("./../../assets/images/blog4.png"),
          title: "Kerala's rogue elephant Arikomban in great shape now",
          text: "Captured wild elephant Arikomban was healthy and trying to integrate with other herds of pachyderms, the Tamil Nadu Forest department said on Sunday.",
        },
      ],
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/blog",
      })
        .then((response) => {
          this.appLoading = false;
          this.blog = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      day = day < 10 ? "0" + day : day;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>